import React from 'react';
import axios from 'axios';
import { Table, TableBody, TableRow, TableCell, TableHead, Checkbox, FormControlLabel, InputAdornment, Box, TextField, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions, Collapse, Link } from '@material-ui/core';
import { API_BASE_URL } from './config';
import SearchIcon from '@material-ui/icons/Search';

function UserDetailsDialog({userID, handleClose}) {

    const [name, setName] = React.useState(undefined);
    const [email, setEmail] = React.useState(undefined);
    const [raw, setRaw] = React.useState({});
    const [roles, setRoles] = React.useState([]);
    const [showDebug, setShowDebug] = React.useState(false);
    const [loadFinished, setLoadFinished] = React.useState(false);

    async function save() {
        if (!loadFinished) {
            alert("not load finished");
            return;
        }

        try {
            const res = await axios.post(`${API_BASE_URL}/api/v1/users/${userID}`, { name: name, email: email });
            console.log("user update res:", res);
            handleClose()
        }
        catch(err) {
            console.error(err);
            alert(`error: ${err.message}`)
        }
    };

    async function load() {
        setLoadFinished(false);
        setRoles([]);
        setRaw({});
        setName(undefined);
        setEmail(undefined);

        try {
            const res = await axios.get(`${API_BASE_URL}/api/v1/users/${userID}`);
            console.log("user get res:", res);

            if (res.data.customAttributes) {
                let attr = JSON.parse(res.data.customAttributes);
                let attr_roles = attr["roles"] ?? []
                setRoles(attr_roles);
            }

            setRaw(res.data);
            setName(res.data.displayName);
            setEmail(res.data.email);
            setLoadFinished(true);
        }
        catch(err) {
            console.error(err);
            alert(`error: ${err.message}`);
        }
    };

    async function resetPassword() {
        try {
            const res = await axios.post(`${API_BASE_URL}/api/v1/users/${userID}/reset_password`);
            console.debug("res:", res);

            const link = res.data['link']
            // alert(link);

            window.open(link);
        }
        catch(err) {
            console.error("bolt_states.json fetch err", err);
            alert(`Error: ${err.message}`)
        }
    }

    async function deleteUser() {
        if (window.confirm("Are you sure?") === true) {
            try {
                const res = await axios.delete(`${API_BASE_URL}/api/v1/users/${userID}`);
                console.debug("delete res:", res);

                handleClose();
            }
            catch(err) {
                console.error(err);
                alert(`Error: ${err.message}`)
            }
        }
    }

    async function testSendAPNS(sandbox) {
        const apnsMessage = {
            "aps": {
                "alert": {
                    "title": "Neu: E-Scooter-Warnungen",
                    // "subtitle": "TestSubtitle",
                    "body" : "Jetzt in deiner Region verfügbar. Klicke für mehr Informationen.",
                },
                "badge": "1",
                "sound": {
                    "name": "bingbong.aiff",
                    "volume": 1,
                    "critical": 1,
                },
                "interruption-level": "active",
                "relevance-score": 0.5,
            },
            // Custom keys are available in the userInfo dictionary of the UNNotificationContent object delivered to your
            "url": "locid:/cmd/openURL?url=https%3A%2F%2Fsms-start.de%2Fsupport%2Fe-roller%2F",
        }

        try {
            const res = await axios.post(`${API_BASE_URL}/api/v1/apl-apns-send`, {
                userID: userID,
                sandbox: sandbox,
                message: apnsMessage,
            });
            console.log("apl-apns send res:", res, res.data);
        }
        catch(err) {
            console.error(err);
            alert(`error: ${err.message}`)
        }
    }

    async function roleCheckChanged(checked, role) {
        console.log("roleCheckChanged", checked, role);

        if (checked === true) {
            try {
                const newRoles = [ ...roles, role];
                const res = await axios.post(`${API_BASE_URL}/api/v1/users/${userID}`, { roles: newRoles });
                console.log("/users res:", res);
            }
            catch(err) {
                console.error("roles post err", err);
            }
        }
        else {
            try {
                let newRoles = [ ...roles ];
                const index = newRoles.indexOf(role);
                if (index > -1) {
                    newRoles.splice(index, 1);
                }

                const res = await axios.post(`${API_BASE_URL}/api/v1/users/${userID}`, { roles: newRoles });
                console.log("/users res:", res);
            }
            catch(err) {
                console.error("roles post err", err);
            }
        }
        await load();
    }

    React.useEffect(() => {
        if(userID) {
            load()
        }
    }, [userID])

    function accountType() {
        if (raw?.providerUserInfo === undefined) {
            return undefined;
        }
        if(raw?.providerUserInfo[0].providerId === "password")
            return "email/password";
        else if(raw?.providerUserInfo[0].providerId === "apple.com")
            return "apple";
        else
            return raw?.providerUserInfo?.providerId;
    }

    function downloadDocument(url) {
        console.log("downloadDocument", url);

        let filename = url.substring(url.lastIndexOf('/')+1);
        console.log("filename:", filename)

        axios({
            url: url,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
            console.log("href:", href);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    return (
        <Dialog
            open={userID !== undefined}
            onClose={handleClose}
            >

            <DialogTitle>Edit User '{raw.email}'</DialogTitle>

            <DialogContent>
                <TextField
                    style={{minWidth: 500}}
                    label="Name"
                    value={name ?? ''}
                    onChange={evt => setName(evt.target.value)}
                    variant="outlined"
                    // InputLabelProps={{ shrink: true }}
                    // helperText="Long descriptive text or additional information that will be displayed in a popup window when the user taps the device icon."
                />

                <TextField
                    style={{minWidth: 500, marginTop: 20}}
                    label="Email"
                    value={email ?? ''}
                    onChange={evt => setEmail(evt.target.value)}
                    variant="outlined"
                    // InputLabelProps={{ shrink: true }}
                    helperText="ACHTUNG: Der Benutzer wird nach einer E-Mail-Änderung in der App automatisch ausgeloggt und muss sich neu anmelden!!!"
                />

                <h4>Info</h4>
                <table>
                    <tr>
                        <td>Letzter Login:</td>
                        <td>{new Date(parseInt(raw?.lastLoginAt ?? "0")).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>Account erstellt:</td>
                        <td>{new Date(parseInt(raw?.createdAt ?? "0")).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>Letzter Refresh:</td>
                        <td>{raw?.lastRefreshAt}</td>
                    </tr>
                    <tr>
                        <td>Account-Typ:</td>
                        <td>{accountType()}</td>
                    </tr>
                </table>

                <h4>Permissions</h4>
                <FormControlLabel
                    label="E-Scooter"
                    control={
                        <Checkbox
                            checked={ roles.includes("lid.escooter") }
                            onChange={ (evt, checked) => roleCheckChanged(checked, "lid.escooter") }
                        />
                    }
                />
                <FormControlLabel
                    label="Traffic lights"
                    control={
                        <Checkbox
                            checked={ roles.includes("lid.traffic_light_green_ext") }
                            onChange={ (evt, checked) => roleCheckChanged(checked, "lid.traffic_light_green_ext") }
                        />
                    }
                />
                <FormControlLabel
                    label="RTB Mitarbeiter / Tester"
                    control={
                        <Checkbox
                            checked={ roles.includes("lid.rtb_employee") }
                            onChange={ (evt, checked) => roleCheckChanged(checked, "lid.rtb_employee") }
                        />
                    }
                />

                <h4>Documents / Uploads</h4>
                <ul>
                { (raw.documents ?? []).map((doc, i) => {
                    console.log("Entered");
                    // Return the element. Also pass key
                    return (
                        <li>
                            <Link onClick={() => downloadDocument(`${API_BASE_URL}/api/v1/users/${userID}/documents/${doc}`)}>
                                {doc}
                            </Link>
                        </li>
                    )
                })}
                </ul>

                <hr />

                <Button onClick={resetPassword}>Reset password</Button>

                <Button onClick={deleteUser}>Delete user account</Button>

                <hr />

                <Button onClick={() => setShowDebug(!showDebug)}>Debug</Button>

                <Collapse in={showDebug}>
                    <Button onClick={() => testSendAPNS(true) }>Test APNS (SANDBOX)</Button>
                    <Button onClick={() => testSendAPNS(false) }>Test APNS (PRODUCTION)</Button>
                    <pre>Last login: {new Date(parseInt(raw?.lastLoginAt)).toLocaleString()}</pre>
                    <pre>user: {JSON.stringify(raw, null, 4)}</pre>
                    <pre>roles: {JSON.stringify(roles, null, 4)}</pre>
                </Collapse>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={save} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export function UsersScreen({auth}) {
    const [users, setUsers] = React.useState(undefined);
    const [searchString, setSearchString] = React.useState("")

    const [userDetailUserID, setUserDetailUserID] = React.useState(undefined);

    const reload = () => {
        const f = async() => {
            try {
                const res = await axios.get(`${API_BASE_URL}/api/v1/users`, {
                    responseType: 'json',
                    params: { query: searchString },
                });
                console.log("/users res:", res);
                setUsers(res.data);
            }
            catch(err) {
                console.error("fetch err", err);
                alert(`error: ${err.message}`);
            }
        }
        f();
    }

    React.useEffect(() => {
      reload();
    }, [searchString]);

    const searchValueTimerRef = React.useRef(null)
    const onSearchValueChange = (evt) => {
        clearTimeout(searchValueTimerRef.current)
        searchValueTimerRef.current = setTimeout(() => {
        setSearchString(evt.target.value)
        }, 500);
    }

    React.useEffect(reload, []);

    if (users === undefined) {
        return (<CircularProgress />)
    }

    return (<>
        <h1>Users</h1>

        <Box>
            <div>
                <TextField id="outlined-search"
                    style={{width: '100%', marginBottom: 30, marginTop: 20}}
                    type="search"
                    placeholder="Search ..."
                    onChange={onSearchValueChange}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                        ),
                    }}
                />
            </div>
        </Box>

        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Permissions</TableCell>
                <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {Object.values(users ?? {}).map(user => {
                    return (<>
                        {/* <Row key={e.major + e.minor} e={e} /> */}
                        <TableRow>
                            <TableCell>{ user.user_id }</TableCell>
                            <TableCell>{ user.display_name }</TableCell>
                            <TableCell>{ user.email }</TableCell>
                            <TableCell>{ user.roles.map(e => e.replace("lid.", "")).join(", ") }</TableCell>
                            <TableCell>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={ () => setUserDetailUserID(user.user_id) }
                                >Edit</Button>
                            </TableCell>
                        </TableRow>
                    </>);
                })}
            </TableBody>
        </Table>


        <UserDetailsDialog
            userID={userDetailUserID}
            handleClose={() => setUserDetailUserID(undefined)}
        />

        {/* <pre>
            {JSON.stringify(users, null, 4)}
        </pre> */}
    </>);
};
